
.layout {
    display: flex;
    flex-direction: column;
  }
    
  .menu {
    padding: 10px;
  }
  
  .menu .menu-item {
    text-decoration: none;
    color: var(--menu-item-color, #A2B1C2);
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    position: relative;
  }

  .menu .menu-item.active {
    color: var(--active-tab-font-color, #FFFFFF);
  }
  
  .menu .menu-item::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: var(--underline-color);
  }

  .menu .menu-item.active::after {
    background-color: var(--active-underline-color);
  }

  .content {
    display: flex;
    flex-grow: 1;
  }

  [data-theme='dark']  .menu .menu-item::after {
    --active-underline-color: #4779EF; 
  }
  
  [data-theme='light']  .menu .menu-item::after {
    --active-underline-color: #4779EF; 
  }

  [data-theme='dark'] .menu .menu-item {
    --menu-item-color: #A2B1C2; 
    --active-tab-font-color: #FFFFFF;
  }
  
  [data-theme='light'] .menu .menu-item {
    --menu-item-color: #818393; 
    --active-tab-font-color: #51459E;
  }