/* src/App.css */
body {
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
}

.player-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 2600px;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  margin-right: 1vw;
}

.recharts-wrapper {
  cursor: pointer;
}
