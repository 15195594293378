.username{
    /* float: left; */
    /* margin-right: 2%; */
    margin-top: 1.5%;
    font-size: 14px;
  }
.switch {
    position: relative;
    width: 60px;
    height: 30px;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    background-color: rgb(4, 52, 73);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    transition: background-image .5s ease-in-out;
    outline: none;
    cursor: pointer;
    overflow: hidden;
}

.switch:checked {
    background-color: rgb(0, 195, 255);
    transition: background-image 1s ease-in-out;
}

.switch:after,
.switch:checked:after {
    content: '';
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
    transform: translateX(0);
    animation: off .5s forwards cubic-bezier(.8, .5, .2, 1.4);
    box-shadow: inset 2px -2px 2px rgba(53, 53, 53, 0.3);
}

@keyframes off {
    0% {
        transform: translateX(30px);
        width: 26px;
    }

    50% {
        width: 40px;
        border-radius: 15px;
    }

    100% {
        transform: translateX(0);
        width: 26px;
    }
}

.switch:checked:after {
    animation: on .5s forwards cubic-bezier(.8, .5, .2, 1.4);
    box-shadow: inset -2px -2px 2px rgba(53, 53, 53, 0.3);
}

@keyframes on {
    0% {
        transform: translateX(0);
        width: 26px;
    }

    50% {
        width: 40px;
        border-radius: 15px;
    }

    100% {
        transform: translateX(30px);
        width: 26px;
    }
}

.switch:checked:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 5px;
    transform-origin: 25px 5px;
    background-color: transparent;
    box-shadow: 2px -1px 0px #fff;
    filter: blur(0px);
    animation: sun .5s forwards ease;
}

@keyframes sun {
    0% {
        transform: rotate(170deg);
        background-color: transparent;
        box-shadow: 2px -1px 0px #fff;
        filter: blur(0px);
    }

    50% {
        background-color: transparent;
        box-shadow: 2px -1px 0px #fff;
        filter: blur(0px);
    }

    90% {
        background-color: #f5daaa;
        box-shadow: 0px 0px 5px #f5deb4,
        0px 0px 10px #f5deb4,
        inset 0px 0px 1px #efd3a3;
        filter: blur(1px);
    }

    100% {
        transform: rotate(0deg);
        background-color: #f5daaa;
        box-shadow: 0px 0px 5px #f5deb4,
        0px 0px 10px #f5deb4,
        inset 0px 0px 1px #efd3a3;
        filter: blur(1px);
    }
}

.switch:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 5px;
    filter: blur(1px);
    background-color: #f5daaa;
    box-shadow: 0px 0px 5px #f5deb4,
    0px 0px 10px #f5deb4,
    inset 0px 0px 1px #efd3a3;
    transform-origin: 25px 5px;
    animation: moon .5s forwards ease;
}

@keyframes moon {
    0% {
        transform: rotate(0deg);
        filter: blur(1px);
    }

    50% {
        filter: blur(1px);
    }

    90% {
        background-color: transparent;
        box-shadow: 2px -1px 0px #fff;
        filter: blur(0px);
    }

    100% {
        transform: rotate(170deg);
        background-color: transparent;
        box-shadow: 2px -1px 0px #fff;
        filter: blur(0px);
    }
}
