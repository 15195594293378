.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.gamemon{
  margin-top: 8px;
  font-size: 17px;
  font-weight: bold;
  /* color: rgb(7, 7, 7); */
}
.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  position: sticky;
  top: 50px;
}

.sidebarWrapper {
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: background-color 0.5s, color 0.5s;
}

.sidebarListItem.active,
.sidebarListItem:hover {
 background-color: #4779EF !important;
  color:#ffffff !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}
.menu-font {
  font-size: 16px;
}
.link{
  text-decoration: none;
}
@media only screen and (min-width: 500px) and (max-width: 749px) {
  .sidebarListItem {
    padding: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .gamemon {
    margin-top: 8px;
    font-size: 10px;
    font-weight: bold;
    /* color: rgb(7, 7, 7); */
  }
  .logo{
  width: 15px;
  height: 15px;
  margin-right: -10px;
  }
  .menu-font {
    font-size: 6px;
  }
  .sidebarIcon{
    font-size: 8px !important;
}
 
}
@media only screen and (min-width: 750px) and (max-width: 1023px) {
  .sidebarListItem {
    padding: 9px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .gamemon {
    margin-top: 8px;
    font-size: 10px;
    font-weight: bold;
    /* color: rgb(7, 7, 7); */
  }
  .logo{
  width: 20px;
  height: 20px;
  margin-right: 5px;
  }
  .menu-font {
    font-size: 8px;
  }
  .sidebarIcon{
    font-size: 10px !important;
}
  
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .sidebarListItem {
    padding: 9px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .gamemon {
    margin-top: 4px;
    font-size: 14px;
    font-weight: bold;
    /* color: rgb(7, 7, 7); */
  }
  .logo{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  }
  .menu-font {
    font-size: 14px;
  }
  .sidebarIcon{
    margin-right: 5px;
    font-size: 15px !important;
}
}
@media (min-width: 1600) and (max-width: 1750) {
  .menu-font {
    font-size: 16px;
  }
  .sidebarIcon{
    margin-right: 5px;
    font-size: 25px !important;
}
}
@media (min-width: 1751) and (max-width: 1950) {
  .menu-font {
    font-size: 22px;
  }
  .sidebarIcon{
    margin-right: 5px;
    font-size: 25px !important;
}
}