.dates{
  margin-left:-3vw;
 }
 .dropdown-containers {
   /* width: 200px; */
   margin-top: 0.5vw;
   margin-left: 0.5vw;
   position: relative;
   display: inline-block;
 
 }

 
 /* Style for the dropdown button */
 .dropdown-buttons {
   height: 30px;
   width: 125px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrows {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-contents {
   width: 125px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-x: hidden;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
   scrollbar-width: thin;
   scrollbar-color: #278ef1 #E2E2E2;
   scroll-behavior: smooth;
 }
 
 /* Style for the scrollable list */
 .scrollable-lists {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-lists > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-lists > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-buttons {
   margin-top: 7vh;
   margin-left: -11vw;
   height: 25px;
   width: 150px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   position: relative;
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
   /* margin-left: 42px !important; */
   vertical-align: top;
 }
 .MuiTypography-colorTextSecondary {
   color: none !important;
 }
 .cards-title{
  font-size: 14px;
  font-weight: bold;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
 }
 .card3-header{
  flex: 1;
text-align: left;
font-size: 14px;
margin: 1vh;
 }
 .card3-content{
  font-size: 12px;
  font-weight: 600;
 }
 .org-fonts{
  font-size: 11px;
  margin-left: 15px;
  white-space: nowrap;
 }
 .org-content1{
  font-size: 12px;
  right: 7vw;
  position:relative;
  top:20px;
 }
 .org-content2{
  font-size: 12px;
  right: 5.5vw;
  position:relative;
  top:20px;

 }
 .org-content3{
  font-size: 12px;
  right: 6.5vw;
  position:relative;
  top:20px;

 }
 .orgprog-label{
  margin-left: 13vw;
  margin-top: -3vh;
 }
 .org-appname{
  font-size: 15px;
 }

 @media only screen and (min-device-width: 750px) 
 and (max-device-width: 1019px)
 {
  .org-appname{
    font-size: 12px;
   }
  .orgprog-label{
    margin-left: 11vw;
    margin-top: -1.7vh;
   }

   .org-fonts{
    font-size: 5px;
   }
   .org-content1{
    font-size: 6px;
    right: 6.5vw;
    position:relative;
    top:15px;
   }
   .org-content2{
    font-size: 6px;
    right: 5vw;
    position:relative;
    top:15px;

   }
   .org-content3{
    font-size: 6px;
    right: 6vw;
    position:relative;
    top:15px;

   }
  .cards-title{
    font-size: 8px;
  font-weight: bold;
  padding-bottom: 3px;
  }
  .card3-header{
    font-size: 9px;
  }
  .card3-content{
    font-size: 8px;
    font-weight: 400;
   }
 .dates{
  margin-left:1vw
 }
 .dropdown-containers {
   /* width: 200px; */
   margin-top: 0.5vw;
   margin-left: 0.5vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-buttons {
   height: 25px;
   width: 80px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 10px;
 }
 
 /* Style for the down arrow */
 .down-arrows {
   position: absolute;
   right: 8px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-contents {
   width: 80px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 10px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-lists {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-lists > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-lists > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-buttons {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 20px;
   width: 80px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 8px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }

}
 @media only screen and (min-device-width: 1020px) 
 and (max-device-width: 1200px)
 {
  .org-appname{
    font-size: 13px;
   }
  .org-fonts{
    font-size: 7px;
  }
  .org-content1{
    font-size: 7px;
    right: 7vw;
    position:relative;
    top:17px;
   }
   .org-content2{
    font-size: 7px;
    right: 6vw;
    position:relative;
    top:17px;

   }
   .org-content3{
    font-size: 8px;
    right: 6.5vw;
    position:relative;
    top:17px;

   }
  .orgprog-label{
    margin-left: 12vw;
    margin-top: -2vh;
   }

  .card3-header{
    font-size: 10px;
  }
  .card3-content{
    font-size: 9px;
    font-weight: 400;
   }
  .org-fonts{
    font-size: 8px;;
   }
   .cards-title{
    font-size: 9px;
  font-weight: bold;
  padding-bottom: 3px;
  }
 .dates{
  margin-left:0vw
 }
 .dropdown-containers {
   /* width: 200px; */
   margin-top: 0.5vw;
   margin-left: 0.5vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-buttons {
   height: 25px;
   width: 105px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 10px;
 }
 
 /* Style for the down arrow */
 .down-arrows {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-contents {
   width: 100px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 10px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-lists {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-lists > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-lists > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-buttons {
   margin-top: 5vh;
   margin-left: -9.5vw;
   height: 25px;
   width: 100px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 10px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }

}
@media only screen and (min-device-width: 1201px) 
and (max-device-width: 1299px)
{
  .org-appname{
    font-size: 12px;
   }
  .cards-title{
    font-size: 13px;
  font-weight: bold;
  padding-bottom: 3px;
  }
  .org-fonts{
    font-size: 10px;
  }
  .org-content1{
    font-size: 10px;
    right: 6.5vw;
    position:relative;
    top:17px;
   }
   .org-content2{
    font-size: 10px;
    right: 5vw;
    position:relative;
    top:17px;

   }
   .org-content3{
    font-size: 10px;
    right: 6vw;
    position:relative;
    top:17px;

   }
  .orgprog-label{
    margin-left: 12vw;
    margin-top: -2.5vh;
   }

  .card3-header{
    font-size: 12px;
  }
  .card3-content{
    font-size: 10px;
    font-weight: 400;
   }
   .dates{
    margin-left: 0vw;
   }
   .custom-buttons {
    margin-top: 7vh;
    margin-left: -7vw;
    height: 25px;
    width: 120px;
    background-color: #4779EF;
    /* padding: 10px; */
    border-radius: 5px;
    color: white;
    font-size: 10px;
    /* font-weight: 600; */
    cursor: pointer;
    border: none;
    outline: none;
  }
  
}
@media only screen and (min-device-width: 1300px) 
and (max-device-width: 1400px)
{
  .cards-title{
    font-size: 14px;
  font-weight: bold;
  padding-bottom: 3px;
  }
  .org-fonts{
    font-size: 11px;
  }
  .org-content1{
    font-size: 11px;
    right: 5.5vw;
    position:relative;
    top:17px;
   }
   .org-content2{
    font-size: 11px;
    right: 4vw;
    position:relative;
    top:17px;

   }
   .org-content3{
    font-size: 11px;
    right: 5.5vw;
    position:relative;
    top:17px;

   }
  .orgprog-label{
    margin-left: 12vw;
    margin-top: -2.5vh;
   }

  .card3-header{
    font-size: 11px;
  }
  .card3-content{
    font-size: 10px;
    font-weight: 400;
   }
  .dates{
    margin-left:0.2vw
   }
   .dropdown-containers {
     /* width: 200px; */
     margin-top: 0.5vw;
     margin-left: 0.5vw;
     position: relative;
     display: inline-block;
   
   }
   
   /* Style for the dropdown button */
   .dropdown-buttons {
     height: 25px;
     width: 135px;
     cursor: pointer;
     padding: 10px;
     border: 1px solid #ccc;
     border-radius: 5px;
     background-color: #fff;
     position: relative;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     font-size: 10px;
   }
   
   /* Style for the down arrow */
   .down-arrows {
     position: absolute;
     right: 10px;
   
   }
   
   /* Style for the dropdown content */
   .dropdown-contents {
     width: 135px;
     position: absolute;
     background-color: #fff;
     border: 1px solid #ccc;
     border-radius: 5px;
     max-height: 200px;
     overflow-y: auto;
     display: block;
     font-size: 12px;
     z-index: 1600;
   }
   
   /* Style for the scrollable list */
   .scrollable-lists {
     padding: 10px;
   }
   
   /* Style for individual list items */
   .scrollable-lists > div {
     padding: 5px;
     cursor: pointer;
     margin-left: -1vw;
     
   }
   
   /* Style for the selected item in all dropdowns */
   .scrollable-lists > div[selected] {
     background: #278ef1;
     color: #fff;
   }
   
   /* CSS */
   .custom-buttons {
     margin-top: 7vh;
     margin-left: -8vw;
     height: 25px;
     width: 125px;
     background-color: #4779EF;
     /* padding: 10px; */
     border-radius: 5px;
     color: white;
     font-size: 12px;
     /* font-weight: 600; */
     cursor: pointer;
     border: none;
     outline: none;
   }
}
@media only screen and (min-device-width: 1400px) 
and (max-device-width: 1600px){
    .dates{
      margin-left:0.5vw
     }
     .dropdown-containers {
       /* width: 200px; */
       margin-top: 0.5vw;
       margin-left: 0.5vw;
       position: relative;
       display: inline-block;
     
     }
     
     /* Style for the dropdown button */
     .dropdown-buttons {
       height: 25px;
       width: 145px;
       cursor: pointer;
       padding: 10px;
       border: 1px solid #ccc;
       border-radius: 5px;
       background-color: #fff;
       position: relative;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       font-size: 10px;
     }
     
     /* Style for the down arrow */
     .down-arrows {
       position: absolute;
       right: 10px;
     
     }
     
     /* Style for the dropdown content */
     .dropdown-contents {
       width: 145px;
       position: absolute;
       background-color: #fff;
       border: 1px solid #ccc;
       border-radius: 5px;
       max-height: 200px;
       overflow-y: auto;
       display: block;
       font-size: 10px;
       z-index: 1600;
     }
     
     /* Style for the scrollable list */
     .scrollable-lists {
       padding: 10px;
     }
     
     /* Style for individual list items */
     .scrollable-lists > div {
       padding: 5px;
       cursor: pointer;
       margin-left: -1vw;
       
     }
     
     /* Style for the selected item in all dropdowns */
     .scrollable-lists > div[selected] {
       background: #278ef1;
       color: #fff;
     }
     
     /* CSS */
     .custom-buttons {
       margin-top: 6vh;
       margin-left: -9.5vw;
       height: 25px;
       width: 145px;
       background-color: #4779EF;
       /* padding: 10px; */
       border-radius: 5px;
       color: white;
       font-size: 12px;
       /* font-weight: 600; */
       cursor: pointer;
       border: none;
       outline: none;
     }

}
@media only screen and (min-device-width: 1601px) 
 and (max-device-width: 1700px)
 {
  .cards-title{
    font-size: 14px;
  font-weight: bold;
  padding-bottom: 3px;
  }
  .org-fonts{
    font-size: 12px;
  }
  .org-content1{
    font-size: 12px;
    right: 6.5vw;
    position:relative;
    top:17px;
   }
   .org-content2{
    font-size: 12px;
    right: 5vw;
    position:relative;
    top:17px;

   }
   .org-content3{
    font-size: 12px;
    right: 6.5vw;
    position:relative;
    top:17px;

   }
  .orgprog-label{
    margin-left: 13vw;
    margin-top: -2.5vh;
   }

  .card3-header{
    font-size: 12px;
  }
  .card3-content{
    font-size: 12px;
    font-weight: 400;
   }
   .dates{
     margin-left:1.2vw
    }
 .dropdown-containers {
   width: 200px;
   margin-top: 1vw;
   /* margin-left: 1vw; */
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-buttons {
   height: 30px;
   width: 165px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrows {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-contents {
   width: 165px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-lists {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-lists > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-lists > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-buttons {
   margin-top: 8vh;
   margin-left: -11vw;
   height: 35px;
   width: 165px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
   .session-note{
     border-radius:'20px';
     /* box-shadow: "5px 5px 5px silver";
     margin-top:"20px"; */
     background-color:rgb(245, 19, 19);
     width:14%;
   
   }
 
 }
 @media only screen and (min-device-width: 1701px) 
 and (max-device-width: 2100px)
 {
  .cards-title{
    font-size: 15px;
  font-weight: bold;
  padding-bottom: 3px;
  }
  .org-fonts{
    font-size: 13px;
  }
  .org-content1{
    font-size: 13px;
    right: 6.5vw;
    position:relative;
    top:22px;
   }
   .org-content2{
    font-size: 13px;
    right: 5vw;
    position:relative;
    top:22px;

   }
   .org-content3{
    font-size: 13px;
    right: 6vw;
    position:relative;
    top:22px;

   }
  .orgprog-label{
    margin-left: 13vw;
    margin-top: -2.5vh;
   }

  .card3-header{
    font-size: 13px;
  }
  .card3-content{
    font-size: 14px;
    font-weight: 400;
   }
   .dates{
     margin-left:1vw
    }
 .dropdown-containers {
   width: 200px;
   margin-top: 1vw;
   margin-left: 1vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-buttons {
   height: 30px;
   width: 160px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 14px;
 }
 
 /* Style for the down arrow */
 .down-arrows {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-contents {
   width: 160px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 14px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-lists {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-lists > div {
   padding: 5px;
   cursor: pointer;
   margin-left: 0.5px;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-lists > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-buttons {
   margin-top: 7vh;
   margin-left: -10vw;
   height: 35px;
   width: 160px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 13px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
   .session-note{
     border-radius:'20px';
     /* box-shadow: "5px 5px 5px silver";
     margin-top:"20px"; */
     background-color:rgb(245, 19, 19);
     width:14%;
   
   }
 
 }