/* Profile.css */

.containerStyle1 {
  width: 80vw;
  margin-top: 5%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 38vh;
  position: relative;
}

.imageStyle {
  max-width: 5vw;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 20px;
  margin-left: 25px;
  object-fit: cover;
}

.LabelStyle {
  font-size: 14px;
  text-align: left;
  margin-left: 6vw;
  margin-top: 2vh;
}

.InputOfLabel {
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Heading {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  margin-left: 1vw;
  margin-top: 5vh;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4vh;
  margin-left: 1vw;
}

.LabelStyle1 {
  width: 20vw;
  text-align: left;
  font-size: 12px;
}

.InputOfLabel1 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.8vh;
}

.containerStyle2 {
  margin-left: 20px;
  width: 28vw;
  margin-top: 5%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.LicenseHeading {
  font-size: 14px;
  text-align: left;
}

.column-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin-top: 1vh;
}

.LicenseLabel {
  width: 20vw;
  text-align: left;
  font-size: 12px;
  margin: 1vh;
  margin-top: 2vh;
}

.LicenseInput {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.8vh;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .containerStyle1 {
    height: 38vh;
  }
  .containerStyle2 {
    height: 55vh;
  }
}

@media (min-width: 1601px) and (max-width: 1919px) {
  .containerStyle1 {
    height: 38vh;
  }
  .containerStyle2 {
    height: 45vh;
  }
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .containerStyle1 {
    height: 40vh;
  }
  .containerStyle2 {
    height: 40vh;
  }
  .LabelStyle {
    font-size: 16px;
    text-align: left;
    margin-left: 6vw;
    margin-top: 2vh;
  }
  
  .InputOfLabel {
    font-size: 14px;
  }
  
  .Heading {
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    margin-left: 1vw;
    margin-top: 5vh;
  }
  
  
  .LabelStyle1 {
    width: 20vw;
    text-align: left;
    font-size: 14px;
  }
  
  .InputOfLabel1 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0.8vh;
  }
  
  .LicenseHeading {
    font-size: 16px;
    text-align: left;
  }
  
  
  .LicenseLabel {
    width: 20vw;
    text-align: left;
    font-size: 14px;
    margin: 1vh;
    margin-top: 2vh;
  }
  
  .LicenseInput {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0.8vh;
  }
}