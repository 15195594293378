.custom-progress-container {
  height: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .metric-usage {
  border-radius: 10px;
  padding: 2%;
  width: 100%;
  height: auto;
  margin: 2%;
  box-shadow: 0px 1px 10px #0000001a;
  cursor: pointer;
  transition: background-color 0.3s; 
} */
.metric-usage {
  background: white;
  cursor: pointer;
  border-radius: 10px;
  padding: 5%;
  width: 95%;
  height: auto;
  margin: 15px;
  box-shadow: 0px 1px 10px #0000001a;
  color: black;
  font-weight: 200;
}

.metric-usage p {
  margin: 0;
  padding-bottom: 5px;
}

.prog {
  display: flex;
  align-items: center;
}


.progress {
  flex-grow: 1;
}

.small-text {
  font-size: 12px;
  margin-left: 10px;
  margin-top: -5px;
}
.metric-usage.selected {
    background: transparent linear-gradient(180deg, #6398F5 0%, #4475EE 100%) 0% 0% no-repeat padding-box;
    color: white;
  }