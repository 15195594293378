.dates{
  margin-left:-3vw
 }
 .dropdown-container {
   /* width: 200px; */
   margin-top: 0.5vw;
   margin-left: 0.5vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-button {
   height: 30px;
   width: 150px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrow {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-content {
   width: 150px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-x: hidden;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
   scrollbar-width: thin;
   scrollbar-color: #278ef1 #E2E2E2;
   scroll-behavior: smooth;
 }
 
 /* Style for the scrollable list */
 .scrollable-list {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-list > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-list > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 25px;
   width: 100px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   position: relative;
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
   /* margin-left: 42px !important; */
   vertical-align: top;
 }
 .MuiTypography-colorTextSecondary {
   color: none !important;
 }
 @media only screen and (min-device-width: 750px) 
 and (max-device-width: 1020px)
 {
  .dates{
   margin-left:-3vw
  }
  .dropdown-container {
    /* width: 200px; */
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    position: relative;
    display: inline-block;
  
  }
  
  /* Style for the dropdown button */
  .dropdown-button {
    height: 20px;
    width: 80px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 7px;
  }
  
  /* Style for the down arrow */
  .down-arrow {
    position: absolute;
    right: 10px;
  
  }
  
  /* Style for the dropdown content */
  .dropdown-content {
    width: 80px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
    font-size: 7px;
    z-index: 1600;
  }
  
  /* Style for the scrollable list */
  .scrollable-list {
    padding: 10px;
  }
  
  /* Style for individual list items */
  .scrollable-list > div {
    padding: 5px;
    cursor: pointer;
    margin-left: -1vw;
    
  }
  
  /* Style for the selected item in all dropdowns */
  .scrollable-list > div[selected] {
    background: #278ef1;
    color: #fff;
  }
  
  /* CSS */
  .custom-metricbutton {
    margin-top: 7vh;
    margin-left: -10.5vw;
    height: 20px;
    width: 80px;
    background-color: #4779EF;
    /* padding: 10px; */
    border-radius: 5px;
    color: white;
    font-size: 7px;
    /* font-weight: 600; */
    cursor: pointer;
    border: none;
    outline: none;
  }

 }
 @media only screen and (min-device-width: 1020px) 
   and (max-device-width: 1100px)
   {
   .dates{
    margin-left:-3vw
   }
   .dropdown-container {
     /* width: 200px; */
     margin-top: 0.5vw;
     margin-left: 0.5vw;
     position: relative;
     display: inline-block;
   
   }
   
   /* Style for the dropdown button */
   .dropdown-button {
     height: 25px;
     width: 110px;
     cursor: pointer;
     padding: 10px;
     border: 1px solid #ccc;
     border-radius: 5px;
     background-color: #fff;
     position: relative;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     font-size: 10px;
   }
   
   /* Style for the down arrow */
   .down-arrow {
     position: absolute;
     right: 10px;
   
   }
   
   /* Style for the dropdown content */
   .dropdown-content {
     width: 110px;
     position: absolute;
     background-color: #fff;
     border: 1px solid #ccc;
     border-radius: 5px;
     max-height: 200px;
     overflow-y: auto;
     display: block;
     font-size: 10px;
     z-index: 1600;
   }
   
   /* Style for the scrollable list */
   .scrollable-list {
     padding: 10px;
   }
   
   /* Style for individual list items */
   .scrollable-list > div {
     padding: 5px;
     cursor: pointer;
     margin-left: -1vw;
     
   }
   
   /* Style for the selected item in all dropdowns */
   .scrollable-list > div[selected] {
     background: #278ef1;
     color: #fff;
   }
   
   /* CSS */
   .custom-metricbutton {
     margin-top: 7vh;
     margin-left: -10.5vw;
     height: 25px;
     width: 110px;
     background-color: #4779EF;
     /* padding: 10px; */
     border-radius: 5px;
     color: white;
     font-size: 12px;
     /* font-weight: 600; */
     cursor: pointer;
     border: none;
     outline: none;
   }

  }
  @media only screen and (min-device-width: 1101px) 
  and (max-device-width: 1270px)
  {
  .dates{
   margin-left:-3vw
  }
  .dropdown-container {
    /* width: 200px; */
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    position: relative;
    display: inline-block;
  
  }
  
  /* Style for the dropdown button */
  .dropdown-button {
    height: 25px;
    width: 120px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
  }
  
  /* Style for the down arrow */
  .down-arrow {
    position: absolute;
    right: 10px;
  
  }
  
  /* Style for the dropdown content */
  .dropdown-content {
    width: 120px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
    font-size: 10px;
    z-index: 1600;
  }
  
  /* Style for the scrollable list */
  .scrollable-list {
    padding: 10px;
  }
  
  /* Style for individual list items */
  .scrollable-list > div {
    padding: 5px;
    cursor: pointer;
    margin-left: -1vw;
    
  }
  
  /* Style for the selected item in all dropdowns */
  .scrollable-list > div[selected] {
    background: #278ef1;
    color: #fff;
  }
  
  /* CSS */
  .custom-metricbutton {
    margin-top: 7vh;
    margin-left: -10.5vw;
    height: 25px;
    width: 120px;
    background-color: #4779EF;
    /* padding: 10px; */
    border-radius: 5px;
    color: white;
    font-size: 12px;
    /* font-weight: 600; */
    cursor: pointer;
    border: none;
    outline: none;
  }

 }
 @media only screen and (min-device-width: 1271px) 
 and (max-device-width: 1399px)
 {
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 25px;
   width: 130px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }

}
 @media only screen and (min-device-width: 1400px) 
 and (max-device-width: 1499px)
 {
 .dates{
  margin-left:-3vw
 }
 .dropdown-container {
   /* width: 200px; */
   margin-top: 0.5vw;
   margin-left: 0.5vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-button {
   height: 30px;
   width: 165px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrow {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-content {
   width: 150px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-list {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-list > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-list > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 25px;
   width: 150px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
}
@media only screen and (min-device-width: 1500px) 
 and (max-device-width: 1599px)
 {
   .dates{
     margin-left:-2vw
    }
 .dropdown-container {
   width: 200px;
   margin-top: 1vw;
   margin-left: 1vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-button {
   height: 30px;
   width: 160px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrow {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-content {
   width: 150px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-list {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-list > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-list > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 35px;
   width: 150px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 } 
  
   .session-note{
     border-radius:'20px';
     /* box-shadow: "5px 5px 5px silver";
     margin-top:"20px"; */
     background-color:rgb(245, 19, 19);
     width:14%;
   
   }
 
 }
 @media only screen and (min-device-width: 1600px) 
 and (max-device-width: 1850px)
 {
   .dates{
     margin-left:-2vw
    }
 .dropdown-container {
   width: 200px;
   margin-top: 1vw;
   margin-left: 1vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-button {
   height: 30px;
   width: 175px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrow {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-content {
   width: 150px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-list {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-list > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-list > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 35px;
   width: 180px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
   .session-note{
     border-radius:'20px';
     /* box-shadow: "5px 5px 5px silver";
     margin-top:"20px"; */
     background-color:rgb(245, 19, 19);
     width:14%;
   
   }
 
 }
 @media only screen and (min-device-width: 1851px) 
 and (max-device-width: 2100px)
 {
   .dates{
     margin-left:-2vw
    }
 .dropdown-container {
   width: 200px;
   margin-top: 1vw;
   margin-left: 1vw;
   position: relative;
   display: inline-block;
 
 }
 
 /* Style for the dropdown button */
 .dropdown-button {
   height: 30px;
   width: 200px;
   cursor: pointer;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #fff;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 
 /* Style for the down arrow */
 .down-arrow {
   position: absolute;
   right: 10px;
 
 }
 
 /* Style for the dropdown content */
 .dropdown-content {
   width: 200px;
   position: absolute;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 5px;
   max-height: 200px;
   overflow-y: auto;
   display: block;
   font-size: 12px;
   z-index: 1600;
 }
 
 /* Style for the scrollable list */
 .scrollable-list {
   padding: 10px;
 }
 
 /* Style for individual list items */
 .scrollable-list > div {
   padding: 5px;
   cursor: pointer;
   margin-left: -1vw;
   
 }
 
 /* Style for the selected item in all dropdowns */
 .scrollable-list > div[selected] {
   background: #278ef1;
   color: #fff;
 }
 
 /* CSS */
 .custom-metricbutton {
   margin-top: 7vh;
   margin-left: -10.5vw;
   height: 35px;
   width: 200px;
   background-color: #4779EF;
   /* padding: 10px; */
   border-radius: 5px;
   color: white;
   font-size: 12px;
   /* font-weight: 600; */
   cursor: pointer;
   border: none;
   outline: none;
 }
 
   .session-note{
     border-radius:'20px';
     /* box-shadow: "5px 5px 5px silver";
     margin-top:"20px"; */
     background-color:rgb(245, 19, 19);
     width:14%;
   
   }
 
 }