.MuiTypography-h6 {
  font-size: 1.25rem;
  color:"black !important";
  font-family: Verdana, sans-serif !important;
  font-weight: 800 !important;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.ag-theme-alpine .ag-root-wrapper {
  border:none !important; 
  border-color: white !important;
  /* border-radius: 25px !important; */
}
.ag-theme-alpine {
  --ag-background-color: white;
  --ag-foreground-color: rgb(97, 94, 94);
  --ag-header-foreground-color: black;
  --ag-header-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-header-column-resize-handle-color: white;

}
.ag-theme-alpine-dark {
  --ag-background-color: #292E33;
  --ag-foreground-color: rgb(97, 94, 94);
  --ag-header-foreground-color: black;
  --ag-header-background-color: #292E33;
  --ag-odd-row-background-color: #292E33;
  --ag-header-column-resize-handle-color: #292E33;
}
.header-dark {
  color: #C5CACF;
  font-size: 14px;
}

.header-light {
  color: #959595;
  font-size: 14px;
}
#table-grid{
  height: 80%;
  width: 100%;
  margin-top: 0px;
  box-shadow: 0px 25px 30px #0000000A;
}
  @media only screen and (min-device-width: 1901px) and (max-device-width: 2000px) {
    #table-grid{
      height: 50%;
      width: 100%;
      margin-top: 0px;
      box-shadow: 0px 25px 30px #0000000A;
    }
    }

