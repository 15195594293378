@media only screen and (min-device-width: 750px) and (max-device-width: 2500px) {
  .ag-theme-alpine {
    --ag-background-color: white;
    --ag-foreground-color: #174A84;
    --ag-header-foreground-color: #174A84;
    --ag-header-background-color: white;
    --ag-odd-row-background-color: white;
    --ag-header-column-resize-handle-color: white;
    color: #174a84;
  }

  .ag-theme-alpine-dark {
    --ag-background-color: #292E33;
    --ag-foreground-color: #FFFFFF;
    --ag-header-foreground-color:#C5CACF;
    --ag-header-background-color: #292E33;
    --ag-odd-row-background-color: #292E33;
    --ag-header-column-resize-handle-color: #292E33;
    color: #FFFFFF;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px;
    line-height: normal;
  }
  .ag-theme-alpine-dark .ag-header-cell{
    padding-left: 18px;
    padding-right: 18px;
  }
  .ag-theme-alpine-dark .ag-cell{
    padding-left: 18px;
    padding-right: 18px;
  }
  .ag-cell.center-align {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  .ag-cell.space-reduce {
    line-height: 1.5;
}

  .plots {
    margin-left: 8%;
    width: "50vw";
    margin-top: "60px";
    margin-right: "80px";
  }
  .ag-theme-alpine-dark .ag-icon-none:before {
    content: "\f11e";
    color: #278ef1;
  }
  .ag-theme-alpine-dark .ag-icon-asc:before {
    content: "\f103";
  }
  .ag-theme-alpine-dark .ag-icon-desc:before {
    content: "\f10e";
  }
  .ag-theme-alpine-dark .ag-icon {
    font-family: "agGridAlpine";
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  .header-dark {
    color: #C5CACF;
    font-size: 14px;
  }

  .header-light {
    color: gray;
    font-size: 14px;
  }
}
.select-metrics{
  margin-left: 38%;
}

@media only screen and (min-device-width: 750px) and (max-device-width:1000px) {
  
.title{
  font-size: 11px;
}
.select-metrics{
  margin-left: 7%;
}
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) {
  .select-metrics{
    margin-left: 20%;
  }
  }
@media only screen and (min-device-width: 1401px) and (max-device-width: 1600px) {
  .select-metrics{
    margin-left: 40%;
  }
}
  @media only screen and (min-device-width: 1601px) and (max-device-width: 1700px) {
    .select-metrics{
      margin-left: 48%;
    }
  }
  @media only screen and (min-device-width: 1701px) and (max-device-width: 1900px) {
    .select-metrics{
      margin-left: 50%;
    }
    }
    @media only screen and (min-device-width: 1901px) and (max-device-width: 2000px) {
      .select-metrics{
        margin-left: 55%;
      }
      }