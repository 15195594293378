body {
  margin: 0;
}

/* loginContainerStyle */
.login-container {
  background-color: #142965;
  height: 75vh;
  width: 85vw;
  margin-left: 70%;
  border-radius: 35px;
  padding: 20px;
  position: relative;
}

/* headingStyle and headingStyle2 */
.heading, .heading2 {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  color: white;
}

.heading2 {
  font-size: 12px;
  font-weight: 200;
}

/* headingStyle3 */
.heading3 {
  color: #83a8ff;
  font-weight: 600;
}

/* labelStyle */
.label {
  text-align: start;
  margin-left: 2vw;
  font-size: 12px;
  color: white;
}

/* inputStyle */
.input {
  margin-top: 5px;
  margin-bottom: 8px;
  border-radius: 5px;
  padding: 8px;
  width: 80%;
  font-size: 12px;
  margin-left: 2vw;
  border: none;
  outline: none;
}

/* passwordToggleBtn */
.password-toggle-btn {
  margin-left: 17.5vw;
  width: 25px;
  transform: translateY(-135%);
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
}

/* rememberMeStyle */
.remember-me {
  display: flex;
  align-items: center;
  margin-left: 2vw;
  color: white;
  font-size: 12px;
  margin-top: -15px;
}

/* signInButtonStyle */
.sign-in-button {
  background: #4374ee;
  border-radius: 5px;
  padding: 8px;
  width: 80%;
  margin-left: 2vw;
  font-size: 14px;
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}

/* privacy */
.privacy, .forget-pass {
  color: white;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
}

/* bottomStyle */
.bottom {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

/* indlogoStyle */
.ind-logo {
  width: 2%;
  height: 2%;
  margin-bottom: 1.5%;
}

/* gameMonStyle */
.game-mon {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1.5%;
  margin-left: 0.5%;
  color: #142965;
}

/* leftSideTextStyle */
.left-side-text {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}

/* leftSideTextStyle2 */
.left-side-text2 {
  color: white;
  font-size: 40px;
  font-weight: 600;
}

/* copyrightStyle */
.copyright {
  color: grey;
  font-size: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.errorText {
  color: red;
  font-size: 12px;
  font-weight: 300;
  text-align: start;
  margin-left: 2vw;
  margin-bottom: -15px;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  /* Adjusted styles for medium screens */
  .login-container {
    height: 85vh;
    width: 85vw;
  }

  .heading2, .input, .remember-me {
    font-size: 12px;
  }

  .password-toggle-btn {
    width: 25px;
    margin-left: 17.5vw;
    transform: translateY(-135%);
  }

  .sign-in-button {
    font-size: 12px;
  }

  .privacy, .forget-pass {
    font-size: 10px;
  }
}

@media (min-width: 1601px) and (max-width: 1920px) {
  /* Adjusted styles for large screens */
  .login-container {
    height: 75vh;
    width: 85vw;
  }

  .heading {
    margin-top: 15%;
  }

  .heading2 {
    font-size: 14px;
    margin-bottom: 6%;
  }

  .input {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .password-toggle-btn {
    width: 25px;
    transform: translateY(-195%);
    margin-left: 18.5vw;
  }

  .remember-me {
    font-size: 14px;
  }

  .sign-in-button {
    font-size: 16px;
    margin-top: 5%;
    padding: 10px;
  }

  .privacy, .forget-pass {
    font-size: 14px;
    margin-top: 20%;
  }

  .game-mon {
    font-size: 25px;
  }

  .left-side-text {
    font-size: 100px;
  }

  .left-side-text2 {
    font-size: 70px;
  }

  .copyright {
    font-size: 12px;
  }
}

@media (min-width: 1921px) and (max-width: 2560px) {
  /* Adjusted styles for extra large screens */
  .login-container {
    height: 75vh;
    width: 85vw;
  }

  .heading {
    margin-top: 15%;
  }

  .heading2 {
    font-size: 16px;
    margin-bottom: 10%;
  }

  .label {
    font-size: 16px;
  }
  .input {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .password-toggle-btn {
    width: 25px;
    transform: translateY(-195%);
    margin-left: 18.5vw;
  }

  .remember-me {
    font-size: 16px;
  }

  .sign-in-button {
    font-size: 18px;
    margin-top: 5%;
    padding: 10px;
  }

  .privacy, .forget-pass {
    font-size: 18px;
    margin-top: 20%;
  }

  .game-mon {
    font-size: 30px;
  }

  .left-side-text {
    font-size: 120px;
  }

  .left-side-text2 {
    font-size: 80px;
  }

  .copyright {
    font-size: 16px;
  }
  .errorText{
    font-size: 14px;
  }
}
